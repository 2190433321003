import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Post extends Component {
  render() {
    const post = this.props.data.wordpressPost

    return (
      <Layout sectionClasses={"t-news"} dataNamespace={"news_detail"}>
        <SEO title={post.title} />

        <div className="t-news__ttl p-title">
          <span className="t-news__ttl__main">NEWS</span>
          <span className="t-news__ttl__sub">INFORMATION&TOPICS</span>
        </div>

        <div className="t-news__detail">
          <div className="t-news__detail__head">
            <p className="t-news__detail__head__caption">NEWS DETAIL</p>
            {/*<div className="t-news__detail__head__buttons">*/}
            {/*  <button className="p-button--prev t-news__detail__head__prev"></button>*/}
            {/*  <button className="p-button--next t-news__detail__head__next"></button>*/}
            {/*</div>*/}
          </div>

          <div className="t-news__detail__box">
            <div className="t-news__detail__box__ttl">
              <p>
                <span className="t-news__detail__box__ttl__date">
                  {post.date}
                </span>
                <span className="t-news__detail__box__ttl__cat">
                  {post.categories[0].name}
                </span>
              </p>
              <h1>{post.title}</h1>
            </div>
            <div
              className="t-news__detail__box__content"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>
          <a className="button" href={"/news/"}>
            ALL NEWS
          </a>
        </div>

        {/*
        <Link class="p-pageBackBtn" to="/">
          <div class="c-grid">
            <div class="c-grid__row">
              <div class="c-grid__col">
                <span class="p-pageBackBtn__inner">BACK TO TOP</span>
              </div>
            </div>
          </div>
        </Link>
        */}
      </Layout>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      date(formatString: "YYYY-MM-DD")
      content
      categories {
        name
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
